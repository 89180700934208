@font-face {
  font-family: 'Anton-Regular';
  src: url('../font/Anton-Regular.ttf');
}

.container{
  display: flex
}

.test {
  width: 100%;
}

.noserver-text {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}    
.noserver-text .shadows {
    display: flex;
    flex-wrap: wrap;
    width: auto;

    position: relative;
    text-transform: uppercase;
    /* text-shadow: -15px 5px 20px #1a2639; */
    color: #344f79 ;
    letter-spacing: -0.05em;
    font-family: 'Anton-Regular';
    user-select: none;
    text-transform: uppercase;
    font-size: 150px;
}




/**
keeping this animation in case of
*/

/*.noserver-text p {
  background: #1a2639;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  display: inline-block;
  border: 4px double rgba(255, 255, 255, .25);
  border-width: 4px 0;
  padding: 1.5em 0em;
  position: absolute;
  top: 18%;
  left: 50%;
  width: 40em;
  margin: 0 0 0 -20em;
}
.noserver-text p span {
  font: 700 4em "Oswald", sans-serif;
  letter-spacing: 0;
  padding: 0.25em 0 0.325em;
  display: block;
  margin: 0 auto;
  text-shadow: 0 0 80px rgba(255, 255, 255, .5);
 /* Clip Background Image */
 /* background: url(http://avante.biz/wp-content/uploads/Gta-V-Wallpaper/Gta-V-Wallpaper-034.jpg) repeat-y;
  -webkit-background-clip: text;
  background-clip: text;
 /* Animate Background Image */
 /* -webkit-text-fill-color: transparent;
  animation: aitf 80s linear infinite;
 /* Activate hardware acceleration for smoother animations */
 /* transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}*/
/* Animate Background Image */
/*@keyframes aitf {
  0% {
    background-position: 0% 50%;
 }
  100% {
    background-position: 100% 50%;
 }
}*/

/** Loading animation */

.animation-box{
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(7, 7, 7);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 675px){

    .container{
      display: block;
    }

      .noserver-text p {
        position: relative;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
      }

      .noserver-text .shadows{
        flex-direction: column;
        font-size: 80px;
      }
    
    
  }