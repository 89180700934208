.App {
  max-width: 100%;
  text-align: center;
}

/*media query*/

@media screen and (max-width: 675px){
  
  .App{
    display: flex;
    flex-direction: column;
  }



}

