.thumbnail{
  display: flex;
  flex-wrap: wrap;
  max-width: 1460px;
  justify-content: space-around;
  margin: auto;
} 

.thumbnail-content{
    position:relative;
    max-width: 440px;
    min-width: 440px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    min-height: 300px;
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border: solid 1px ;
    transition: 0.3s;
    overflow: hidden;
}

.thumbnail a {
  text-decoration: none;
}

.thumbnail-content img {
  width: 100%;
  height: auto;
  overflow:hidden;
}


.thumbnail .user-content{
    display: flex;
    justify-content: space-between;
    color: hsl(217, 40%, 34%);
}

.thumbnail-content .user-content h3{
  height: 48px;
  
}

  /*layer animation*/

  .thumbnail-content .card .layer {
    z-index:1;
    position:absolute;
    top:calc(100% - 2px);
    height:100%;
    width:100%;
    left:0;
    background:hsl(217, 40%, 34%);
    transition:0.5s;    
  }

  .thumbnail-content .card .card-content .user-content {
    font-size:14px;
    line-height:24px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
  }

  .thumbnail-content .card .card-content {
    z-index:2; 
    position:relative;
  
  }

 .thumbnail-content .card:hover .layer{
  top:68%;
}

.thumbnail-content .card:hover .card-content .user-content {
  color: white;
}







  /* animation*/
.fade-in-image {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }





@media screen and (max-width: 675px){

  .thumbnail{
    max-width: 360px;
    margin: auto;
    z-index: 1;
    margin-top: 60px;
  }

  .thumbnail-content{
    
    min-width: auto;
    margin: auto;
  }



}