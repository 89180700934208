ul {
  padding: 0;
}

.filter-content .menu-button{
  width: 300px;
  display: none;
}

.filter-content .menu-button img{
  width: 30px;
  
}

.filter-content{
    max-width: 300px;
}

.filter-content ul {
  transition: 0.3s;
}

.filter-content .button{

        display: inline-flex;
        height: 40px;
        width: 150px;
        border: 1px solid #1a2639;
        margin: 20px 20px 20px 20px;
        color: #1a2639;
        text-transform: uppercase;
        text-decoration: none;
        font-size: .8em;
        letter-spacing: 1.5px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      
}

#button-4 {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  
  #button-4 a {
    position: relative;
    transition: all .45s ease-Out;
  }
  
  #underline {
    width: 100%;
    height: 2.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -200px;
    background: #1a2639;
    position: absolute;
    transition: all .3s ease-Out;
    bottom: 0;
  }
  
  #button-4:hover #underline {
    left: 0;
  }

  @media screen and (max-width: 675px){

  .filter-content{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: absolute;
    z-index: 3;
    background: white;    
  }

  .filter-content .button{
    width: 99%;    
    margin: 0;
    border: 0px;
    border-bottom: 1px solid;
  }

  .filter-content li:nth-child(1){
    border-top: 1px solid;
  }

  .filter-content .menu-button {
    width: 100%;
    height: 60px;
    display: flex;
    margin-left: 20px;

  }


  
  
  
  }
  