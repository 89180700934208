@font-face {
  font-family: 'GTA';
  src: url('../font/pricedow.ttf');
}

.banner{ 
        color: hsl(217, 40%, 34%);;
        height: 130px;
        font-family: 'GTA';
        font-size: 100px;
        text-transform: uppercase; 
}

.banner img{
    width: 100%;
    height: auto;
}

@media screen and (max-width: 675px){

  .banner {
    height: 50px;
    font-size: 50px;
  }
}
